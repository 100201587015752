<template>
  <div id="elevatorStatus" style="width: 100%; height: 100%" v-loading="loading"></div>
</template>

<script>
export default {
  data() {
    return {
      echartData: [],
      loading: false
    };
  },

  mounted() {
    this.getElevatorStatusCount();
  },

  methods: {
    getElevatorStatusCount() {
      this.loading = true;
      this.$api.getData("elevators/count/status").then(res => {
        const arr = [];
        res.data.forEach(item => {
          arr.push({
            value: item[1],
            name: item[0]
          });
        });
        this.echartData = arr;
        this.initCharts();
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },

    initCharts() {
      let myChart = this.$echarts.init(document.getElementById("elevatorStatus"));
      let option = {
        legend: {
          bottom:"5%"
        },
        color: ["#00D09C", "#FDBD50", "#CCCCCC"],
        series: [
          {
            type: "pie",
            radius: ["40%", "55%"],
            data: this.echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              color:"#1A1A1A",
              formatter: "{c}台",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  }
};
</script>
